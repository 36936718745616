@import (reference) '../../shared/spry/less/lib/spry-variables.less';

.forgot-password-link,
.forgot-username-link {
  font-weight: 700;
  color: @link-color;
  &:hover {
    color: #f6fafd;
  }
}

.signin-content .white-label {
  label {
    color: white !important;
  }
  .no-white-label {
    label {
      color: @heading-color !important;
    }
  }
}
