﻿@import "./lib/spry-variables.less";

@dark-background-color: fade(darken(@complimentary-background-color, 5%), 85%);
@light-background-color: fade(@background-white, 75%);
@gradient-begin: 10%;
@gradient-end: 100%;

html .background-gradient-cover, 
.background-gradient-cover .pure-g [class *= "pure-u"] {
  font-weight: 500;
  color: @heading-color;
}
.background-gradient-cover button {
  font-weight: 500;
}


html,
body#login-page,
#product-background {
  height: 100%;
  margin: 0;
  padding: 0;
  background-size: cover;
  display: flex;
  flex: 1 0 auto;
}
body {
}
#product-background {
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  align-content: stretch;
}
.background-gradient-cover {
  /* IE9 SVG, needs conditional override of 'filter' to 'none' */
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzNlNTk3OCIgc3RvcC1vcGFjaXR5PSIwLjgiLz4KICAgIDxzdG9wIG9mZnNldD0iODUlIiBzdG9wLWNvbG9yPSIjZmZmZmZmIiBzdG9wLW9wYWNpdHk9IjAuOCIvPgogICAgPHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjZmZmZmZmIiBzdG9wLW9wYWNpdHk9IjAuOCIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
  background: -moz-linear-gradient(top,  @dark-background-color 0%, @dark-background-color @gradient-begin,@light-background-color @gradient-end,@light-background-color 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,@dark-background-color), color-stop(@gradient-begin,@dark-background-color), color-stop(@gradient-end,@light-background-color), color-stop(100%,@light-background-color)); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top,  @dark-background-color 0%,@dark-background-color @gradient-begin,@light-background-color @gradient-end,@light-background-color 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top,  @dark-background-color 0%,@dark-background-color @gradient-begin,@light-background-color @gradient-end,@light-background-color 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, @dark-background-color 0%,@dark-background-color @gradient-begin,@light-background-color @gradient-end,@light-background-color 100%); /* IE10+ */
  background: linear-gradient(to bottom,  @dark-background-color 0%,@dark-background-color @gradient-begin,@light-background-color @gradient-end,@light-background-color 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cc3e5978', endColorstr='#ccffffff',GradientType=0 ); /* IE6-8 */
  min-height: 49rem;
  display: flex;
  flex: 1 0 auto;
  justify-content: center;
  align-items: stretch;
}
input,
textarea,
select {
  border-radius: 0;
}
.flex-vertical {
  display: flex;
  flex-grow: 1;
  flex-flow: column nowrap;
  align-items: stretch;
  align-content: space-between;
  justify-content: center;
  .flex-top {
    flex: 1 0 auto;
    padding-bottom: 2rem;
  }
  .flex-bottom {
    flex: 0;
    width: 78em;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 2rem;
  }
}

.content {
  //max-width: 78em;
  width: 78em;
  margin-left: auto;
  margin-right: auto;
}

.login-page a {
  color: @attention;
}
.product-title {
  font-size: 8.25rem;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  display: block;
  line-height: 1;
}
.company-name {
  color: #fff;
}
#login-form {
  width: 29rem;
  margin-left: auto;
  margin-right: auto;
  padding-top: 3rem;
  * {
    box-sizing: border-box;
  }
}
#request-password {
  display: none;
}
#login-db-logo {
  background: transparent url('../images/matrix-dev-login.png') no-repeat center center;
  background-size: contain;
  height: 9.5em;
}
.sign-in-button {

}
.checkbox-field-blue  {
  color: @heading-color;
  input[type="checkbox"] ~ label:before {
    color: @heading-color;
    //content: "\f0c8";
  }
  input[type="checkbox"]:checked ~ label:before {
    color: @heading-color;
  }
}
.notice-message {
  color: @heading-color;
}
#footer {
  background: @heading-color;
  flex: 0;
  min-height: 5em;
  color: @foreground-reverse;
  .matrix-logo {
    font-size: 2.6em;
    margin: .25em 0 0 0;
    .matrix {
      background-size: 8.36rem 1.09rem;
      background-image: url(../images/matrix-swoosh-white.svg);
      background-position-y: .125em;
    }
  }
  .tagline {
    color: @foreground-reverse;
    font-size: 1.5em;
    font-style: italic;
    font-family: "aller";
    font-weight: normal;
  }
  .footer-text {
    color: @foreground-reverse;
    padding-top: 1.35rem;
    text-align: right;
  }
}
