html .background-gradient-cover,
.background-gradient-cover .pure-g [class*="pure-u"] {
  font-weight: 500;
  color: #123044;
}
.background-gradient-cover button {
  font-weight: 500;
}
html,
body#login-page,
#product-background {
  height: 100%;
  margin: 0;
  padding: 0;
  background-size: cover;
  display: flex;
  flex: 1 0 auto;
}
#product-background {
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  align-content: stretch;
}
.background-gradient-cover {
  /* IE9 SVG, needs conditional override of 'filter' to 'none' */
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzNlNTk3OCIgc3RvcC1vcGFjaXR5PSIwLjgiLz4KICAgIDxzdG9wIG9mZnNldD0iODUlIiBzdG9wLWNvbG9yPSIjZmZmZmZmIiBzdG9wLW9wYWNpdHk9IjAuOCIvPgogICAgPHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjZmZmZmZmIiBzdG9wLW9wYWNpdHk9IjAuOCIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
  background: -moz-linear-gradient(top, rgba(54, 77, 103, 0.85) 0%, rgba(54, 77, 103, 0.85) 10%, rgba(255, 255, 255, 0.75) 100%, rgba(255, 255, 255, 0.75) 100%);
  /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(54, 77, 103, 0.85)), color-stop(10%, rgba(54, 77, 103, 0.85)), color-stop(100%, rgba(255, 255, 255, 0.75)), color-stop(100%, rgba(255, 255, 255, 0.75)));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, rgba(54, 77, 103, 0.85) 0%, rgba(54, 77, 103, 0.85) 10%, rgba(255, 255, 255, 0.75) 100%, rgba(255, 255, 255, 0.75) 100%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, rgba(54, 77, 103, 0.85) 0%, rgba(54, 77, 103, 0.85) 10%, rgba(255, 255, 255, 0.75) 100%, rgba(255, 255, 255, 0.75) 100%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(top, rgba(54, 77, 103, 0.85) 0%, rgba(54, 77, 103, 0.85) 10%, rgba(255, 255, 255, 0.75) 100%, rgba(255, 255, 255, 0.75) 100%);
  /* IE10+ */
  background: linear-gradient(to bottom, rgba(54, 77, 103, 0.85) 0%, rgba(54, 77, 103, 0.85) 10%, rgba(255, 255, 255, 0.75) 100%, rgba(255, 255, 255, 0.75) 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#cc3e5978', endColorstr='#ccffffff', GradientType=0);
  /* IE6-8 */
  min-height: 49rem;
  display: flex;
  flex: 1 0 auto;
  justify-content: center;
  align-items: stretch;
}
input,
textarea,
select {
  border-radius: 0;
}
.flex-vertical {
  display: flex;
  flex-grow: 1;
  flex-flow: column nowrap;
  align-items: stretch;
  align-content: space-between;
  justify-content: center;
}
.flex-vertical .flex-top {
  flex: 1 0 auto;
  padding-bottom: 2rem;
}
.flex-vertical .flex-bottom {
  flex: 0;
  width: 78em;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 2rem;
}
.content {
  width: 78em;
  margin-left: auto;
  margin-right: auto;
}
.login-page a {
  color: #C45900;
}
.product-title {
  font-size: 8.25rem;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  display: block;
  line-height: 1;
}
.company-name {
  color: #fff;
}
#login-form {
  width: 29rem;
  margin-left: auto;
  margin-right: auto;
  padding-top: 3rem;
}
#login-form * {
  box-sizing: border-box;
}
#request-password {
  display: none;
}
#login-db-logo {
  background: transparent url('../images/matrix-dev-login.png') no-repeat center center;
  background-size: contain;
  height: 9.5em;
}
.checkbox-field-blue {
  color: #123044;
}
.checkbox-field-blue input[type="checkbox"] ~ label:before {
  color: #123044;
}
.checkbox-field-blue input[type="checkbox"]:checked ~ label:before {
  color: #123044;
}
.notice-message {
  color: #123044;
}
#footer {
  background: #123044;
  flex: 0;
  min-height: 5em;
  color: rgba(255, 255, 255, 0.8);
}
#footer .matrix-logo {
  font-size: 2.6em;
  margin: 0.25em 0 0 0;
}
#footer .matrix-logo .matrix {
  background-size: 8.36rem 1.09rem;
  background-image: url(../images/matrix-swoosh-white.svg);
  background-position-y: 0.125em;
}
#footer .tagline {
  color: rgba(255, 255, 255, 0.8);
  font-size: 1.5em;
  font-style: italic;
  font-family: "aller";
  font-weight: normal;
}
#footer .footer-text {
  color: rgba(255, 255, 255, 0.8);
  padding-top: 1.35rem;
  text-align: right;
}

/*# sourceMappingURL=spry-login.css.map */
