@import 'spry-variables.less';
@import 'spry-mixins.less';

  .fontawesome-setup(@unicode) {
    &:before, &:after {
      content: @unicode;
      font-family: FontAwesome;
    }
  }

  .icomoon-setup(@unicode) {
    &:before, &:after {
      content: @unicode;
      font-family: icomoon;
    }
  }

  .icomoon-matrix-setup(@unicode) {
    &:before, &:after {
      content: @unicode;
    }
  }

  // Specialized Icons
  .vertical-ellipsis-icon {
    position: relative;
  
    &:before, &:after {
      content: '\f142';
      font-family: FontAwesome;
      font-size: .7rem;
      line-height: 1em;
      top: 0.1em;
      display: inline;
    }
  
    &:after {
      display: none;
    }
  }
  
  .filetype-icon {
    .fontawesome-setup('\f016');
  
    .lock-icon {
      position: absolute;
      left: -0.4rem;
      top: 0.7rem;
      display: block;
      width: 1em;
      height: 1em;
  
      &:before {
        content: '\e640';
        display: block;
        color: #c00;
        font-family: icomoon-matrix;
        font-size: 1.1rem;
        font-style: normal;
        position: absolute;
        padding-right: 0;
      }
    }
  
    &.word {
      &:before {
        content: '\f1c2';
        color: @word-doc;
      }
    }
  
    &.pdf {
      &:before {
        content: '\f1c1';
        color: @pdf;
      }
    }
  
    &.pdf-searchable {
      &:before {
        font-family: icomoon-matrix;
        content: '\e982';
        color: @pdf;
      }
    }
  
    &.pdf-secure2 {
      &:before {
        font-family: icomoon-matrix;
        content: '\e989';
        color: @pdf;
      }
    }
  
    &.video {
      &:before {
        content: '\f03d';
        color: @video;
      }
    }
  
    &.image {
      &:before {
        content: '\f03e';
        color: @image;
      }
    }
  
    &.html {
      &:before {
        content: '\f1c9';
        color: @html;
      }
    }
  
    &.audio {
      &:before {
        content: '\f028';
        color: @audio;
      }
    }
  
    &.email {
      &:before {
        content: '\f003'; /* fa-envelope-e */
        color: @text-color-light;
      }
    }
  
    &.powerpoint {
      &:before {
        content: '\f1c4'; /* fa-file-powerpoint-o */
        color: @powerpoint-doc;
      }
    }
  
    &.txt {
      &:before {
        content: '\f0f6'; /* fa-file-text-o */
        color: @word-doc;
      }
    }
  
    &.excel {
      &:before {
        content: '\f1c3'; /* fa-file-excel-o */
        color: @excel-doc;
      }
    }
  
    &.unknown {
      &:before {
        content: '\f016'; /* fa-file-o */
        color: @text-color-light;
      }
    }
  
    &.zip {
      &:before {
        content: '\f1c6'; /* fa-file-zip-o */
        color: @zip;
      }
    }
  }

  .deadline-icon {
    .fontawesome-setup('\f133');
  
    .deadline-overlay-setup(@unicode, @color) {
      position: absolute;
      top: -.1em;
      left: .4em;
      display: block;
      width: 1.5em;
      height: 1.5em;
  
      &:before {
        content: '\f111';
        display: block;
        color: #fff;
        font-family: FontAwesome;
        font-size: 1.4rem;
        position: absolute;
        top: 0;
        left: 0;
        padding-right: 0;
      }
  
      &:after {
        content: @unicode;
        display: block;
        color: @color;
        font-family: FontAwesome;
        font-size: 1.3rem;
        position: absolute;
        top: 0;
        left: .1em;
        padding-right: 0;
      }
    }
  
    .okay-icon {
      .deadline-overlay-setup('\f058', #37b34a);
    }
  
    .warning-icon {
      .deadline-overlay-setup('\f017', #faaf40);
    }
  
    .late-icon {
      .deadline-overlay-setup('\f06a', #ec1c24);
    }
  }

  .bullet-icon {
    display: block;
    padding-left: 2em;
  
    &:before {
      position: absolute;
      margin-left: -1.5em;
      line-height: 1;
    }
  }

  // Regular Icons
  .home-icon {
    .icomoon-matrix-setup('\e600');
  }

  .list-icon {
    .fontawesome-setup('\f03a');
  }

  .square-icon {
    .fontawesome-setup('\f0c8');
  }

  .square-icon-1-2x {
    .fontawesome-setup('\f0c8');

    &:before, &:after {
      font-size: .7rem;
      top: -0.1em;
    }
  }

  .filter-icon {
    .icomoon-matrix-setup('\e63c');
  }

  .confidential-icon {
    .fontawesome-setup('\f070');
    color: @attention;
  }

  .car-icon {
    .fontawesome-setup('\f1b9');
  }

  .dollar-icon {
    .fontawesome-setup('\f155');
  }

  .house-icon {
    .fontawesome-setup('\f015');
  }

  .restricted-icon {
    .fontawesome-setup('\f056');
    color: @attention;
  }

  .status-icon {
    .fontawesome-setup('\f02e');
    color: @text-color-dark;
  }

  .juvenile-icon {
    .icomoon-matrix-setup('\e634');
    color: @attention;
    text-transform: uppercase;
  }

  .arrested-icon {
    .icomoon-matrix-setup('\e633');
    color: @text-color-dark;
  }

  .incarcerated-icon {
    .fontawesome-setup('\f084');
  }

  .defendant-icon {
    .icomoon-matrix-setup('\e606');
  }

  .defendants-icon {
    .icomoon-matrix-setup('\e61d');
  }

  .victim-icon {
    .icomoon-matrix-setup('\e605');
  }

  .witness-icon {
    .icomoon-matrix-setup('\e653');
  }

  .law-enforcement-officer-witness-icon {
    .law-officer-icon();
  }

  .other-party-icon {
    .icomoon-matrix-setup('\e654'); //e905
  }

  .subject-icon {
    .icomoon-matrix-setup('\e654'); //e905
  }

  .suspect-icon {
    .icomoon-matrix-setup('\e61c'); //e906
  }

  .criminal-icon {
    .icomoon-matrix-setup('\e633');
  }

  .contact-icon {
    .icomoon-matrix-setup('\e636');
  }

  .law-officer-icon {
    .icomoon-matrix-setup('\e637');
  }

  .attorney-icon {
    .icomoon-matrix-setup('\e639');
  }

  .undo-icon {
    .fontawesome-setup('\f0e2');
  }

  .trash-icon {
    .fontawesome-setup('\f014');
  }

  .pin-icon {
    .fontawesome-setup('\f08d');
  }

  .case-icon {
    .fontawesome-setup('\f114');
  }

  .case-open-icon {
    .fontawesome-setup('\f115');
  }

  .clock-icon {
    .fontawesome-setup('\f017');
  }

  .age-icon {
    .icomoon-matrix-setup("\e61f");
  }

  .tasks-icon {
    .icomoon-matrix-setup("\e955");
  }

  .linked-icon {
    .icomoon-matrix-setup("\e626");
  }

  .add-icon {
    .fontawesome-setup('\f055');
  }

  .open-left-icon {
    .fontawesome-setup('\f053');
  }

  .caret-left-icon {
    .fontawesome-setup('\f0d9');
  }

  .open-right-icon {
    .fontawesome-setup('\f054');
  }

  .caret-right-icon {
    .fontawesome-setup('\f0da');
  }

  .open-down-icon {
    .fontawesome-setup('\f078');
  }

  .open-up-icon {
    .fontawesome-setup('\f077');
  }

  .open-down-circle-icon {
    .fontawesome-setup('\f13a')
  }

  .open-up-circle-icon {
    .fontawesome-setup('\f139')
  }

  .attach-icon {
    .fontawesome-setup('\f0c6');
  }

  .discussion-icon {
    .info-icon();
  }

  .txt-icon {
    .fontawesome-setup('\f0f6');
  }

  .upload-icon {
    .fontawesome-setup('\f093');
  }

  .play-circle-icon {
    .fontawesome-setup('\f144');
  }

  .stop-circle-icon {
    .fontawesome-setup('\f28d');
  }

  .play-circle-inverse-icon {
    .fontawesome-setup('\f01d');
  }

  .active .filetype-icon.word {
    color: lighten(@word-doc, 50%);
  }

  .active .filetype-icon.pdf {
    color: lighten(@pdf, 50%);
  }

  .active .filetype-icon.video {
    color: lighten(@video, 50%);
  }

  .active .filetype-icon.image {
    color: lighten(@image, 50%);
  }

  .active .filetype-icon.html {
    color: lighten(@html, 50%);
  }

  .active .filetype-icon.audio {
    color: lighten(@audio, 50%);
  }

  .active .filetype-icon.email {
    color: lighten(@text-color-light, 50%);
  }

  .active .filetype-icon.powerpoint {
    color: lighten(@powerpoint-doc, 50%);
  }

  .active .filetype-icon.txt {
    color: lighten(@text-color-light, 50%);
  }

  .active .filetype-icon.excel {
    color: lighten(@excel-doc, 50%);
  }

  .active .filetype-icon.unknown {
    color: lighten(@text-color-light, 50%);
  }

  .cancel-icon {
    .fontawesome-setup('\f00d');
  }

  .minus-icon {
    .fontawesome-setup('\f068');
  }

  .view-icon {
    .fontawesome-setup('\f06e');
  }

  .unview-icon {
    .fontawesome-setup('\f070');
  }

  .ban-icon {
    .fontawesome-setup('\f05e');
  }

  .edit-icon {
    .fontawesome-setup('\f044');
  }

  .pencil-icon {
    .fontawesome-setup('\f040');
  }

  .complete-icon {
    .fontawesome-setup('\f00c');
  }

  .download-icon {
    .fontawesome-setup('\f019');
  }

  .edit-solid-icon {
    .fontawesome-setup('\f14b');
  }

  .details-icon {
    .fontawesome-setup('\f016');
  }

  .recycle-icon {
    .trash-icon();
  }

  .search-icon {
    .fontawesome-setup('\f002');
  }

  .remove-icon {
    .fontawesome-setup('\f057');
  }

  .withheld-icon {
    .fontawesome-setup('\f00d');
  }

  .restore-icon {
    .fontawesome-setup('\f0e2');
  }

  .barcode-icon {
    .fontawesome-setup('\f02a');
  }

  .arrows-icon {
    .fontawesome-setup('\f047');
  }

  .delete-permanently-icon {
    .remove-icon();
  }

  .calendar-list-icon {
    .fontawesome-setup('\f036');

    &:before {
      font-size: 1.5rem;
    }
  }

  .calendar-month-icon {
    .fontawesome-setup('\f0ce');

    &:before {
      font-size: 1.5rem;
    }
  }

  .group-icon {
    .fontawesome-setup('\f114');
  }

  .group-open-icon {
    .fontawesome-setup('\f115');
  }

  .locked-icon {
    .fontawesome-setup('\f023');
  }

  .unlocked-icon {
    .fontawesome-setup('\f13e');
  }

  .calendar-week-icon {
    .icomoon-matrix-setup('\e63a');
  }

  .create-matter-icon {
    .icomoon-matrix-setup('\e63f');
  }

  .icomoon-list-icon {
    .icomoon-matrix-setup('\e611');
  }

  .docket-icon {
    .icomoon-list-icon();
  }

  .docket-add-icon {
    .icomoon-matrix-setup('\e63b');
  }

  .settings-icon {
    .icomoon-matrix-setup('\e641');
  }

  .worklist-icon {
    .icomoon-matrix-setup('\e616');
  }

  .office-calendar-icon {
    .icomoon-matrix-setup('\e614');
  }

  .address-book-icon {
    .icomoon-matrix-setup('\e636');
  }

  .notifications-icon {
    .fontawesome-setup('\f0a2');
  }

  .unnotify-icon {
    .fontawesome-setup('\f1f7');
  }

  .password-icon {
    .icomoon-matrix-setup('\e640');
  }

  .logout-icon {
    .icomoon-matrix-setup('\e644');
  }

  .login-icon {
    .icomoon-matrix-setup('\e643');
  }

  .message-icon {
    .icomoon-matrix-setup('\e642');
  }

  .users-icon {
    .icomoon-matrix-setup('\e61e');
  }

  .user-icon {
    .fontawesome-setup('\f007');
  }

  .word-templates-icon {
    .icomoon-matrix-setup('\e62d');
  }

  .matter-icon {
    .icomoon-matrix-setup("\e609");
  }

  .incident-icon {
    .icomoon-matrix-setup("\e624");
  }

  .cases-icon {
    .icomoon-matrix-setup("\e611");
  }

  .parties-icon {
    .icomoon-matrix-setup("\e61b");
  }

  .items-icon {
    .icomoon-matrix-setup("\e627");
  }

  .reference-only-icon {
    .fontawesome-setup('\f097');
  }

  .reference-item-icon {
    .fontawesome-setup('\f0ea');
  }

  .charging-icon {
    .icomoon-matrix-setup("\e64b");
  }

  .documents-icon {
    .icomoon-matrix-setup("\e649");
  }

  .calendar-icon {
    .icomoon-matrix-setup("\e613");
  }

  .notes-icon {
    .icomoon-matrix-setup("\e608");
  }

  .filter-none-icon {
    .icomoon-matrix-setup('\e63d');
  }

  .psych-eval-icon {
    .icomoon-matrix-setup('\e638');
  }

  .date-featured-icon {
    .icomoon-matrix-setup('\e645');
  }

  .convictions-icon {
    .icomoon-matrix-setup('\e601');
  }

  .gun-icon {
    .icomoon-matrix-setup('\e602');
  }

  .crimes-icon {
    .gun-icon();
  }

  .open-cases-icon {
    .icomoon-matrix-setup('\e603');
  }

  .closed-cases-icon {
    .icomoon-matrix-setup('\e604');
  }

  .save-icon {
    .icomoon-matrix-setup('\e617');
  }

  .lock-icon {
    .icomoon-matrix-setup('\e640');
  }

  .docket-by-info-icon {
    .icomoon-matrix-setup('\e01e');
  }

  .set-time-icon {
    .icomoon-matrix-setup('\e10a');
  }

  .docket-reschedule-icon {
    .icomoon-matrix-setup('\e646');
  }

  .docket-remove-icon {
    .icomoon-matrix-setup('\e647');
  }

  .docket-split-decision-icon {
    .icomoon-matrix-setup('\e648');
  }

  .docket-diversion-icon {
    .icomoon-matrix-setup('\e649');
  }

  .drugs-icon {
    .icomoon-matrix-setup('\e63e');
  }

  .view-schedule-icon {
    .icomoon-matrix-setup('\e64a');
  }

  .watch-icon {
    .fontawesome-setup('\f06e')
  }

  .unwatch-icon {
    .fontawesome-setup('\f070')
  }

  .trash-icon {
    .fontawesome-setup('\f1f8')
  }

  .alert-icon {
    .fontawesome-setup('\f071');
  }

  .question-icon {
    .fontawesome-setup('\f128');
  }

  .times-icon {
    .fontawesome-setup('\f00d');
  }

  .party-alert-icon {
    .alert-icon();
    color: @attention;
  }

  .warning-icon {
    .fontawesome-setup('\f071');
    color: @priority-red;
  }

  .arrow-right-icon {
    .fontawesome-setup('\f061');
  }

  .court-icon {
    .fontawesome-setup('\f19c');
  }

  .organization-icon {
    .icomoon-setup('\e004');
  }

  .info-icon {
    .fontawesome-setup('\f0e5');
  }

  .switch-icon {
    .icomoon-setup('\e2d6');
  }

  .preview-icon {
    .fontawesome-setup('\f06e');
  }

  .external-link-icon {
    .fontawesome-setup('\f08e');
  }

  .notified-icon {
    .fontawesome-setup('\f0e0');
  }

  .thumbs-up-icon {
    .fontawesome-setup('\f087');
  }

  .thumbs-down-icon {
    .fontawesome-setup('\f088');
  }

  .true-bill-outcome-icon {
    .thumbs-up-icon();
  }

  .no-bill-outcome-icon {
    .thumbs-down-icon();
  }

  .continued-outcome-icon {
    .icomoon-matrix-setup('\e64c');
  }

  .signin-icon {
    .icomoon-matrix-setup('\e64d');

    &:before {
      line-height: 1;
    }
  }

  .split-outcome-icon {
    .thumbs-down-icon();

    &:before {
      line-height: 1;
    }
  }

  .refresh-icon {
    .fontawesome-setup('\f021');
  }

  .refresh-square-icon {
    .fontawesome-setup('\f079');
  }

  .check-circle-icon {
    .fontawesome-setup('\f058');
  }

  .check-square-icon {
    .fontawesome-setup('\f046');
  }

  .check-icon {
    .fontawesome-setup('\f00c');
  }

  .question-circle-icon {
    .fontawesome-setup('\f059');
  }

  .info-circle-icon {
    .fontawesome-setup('\f05a');
  }

  .angle-down-icon {
    .fontawesome-setup('\f107');
  }

  .angle-up-icon {
    .fontawesome-setup('\f106');
  }

  .map-marker-icon {
    .icomoon-matrix-setup('\e911');
  }

  .new-window-icon {
    .fontawesome-setup('\f14c');
  }

  .not-included-icon {
    .fontawesome-setup('\f05c');
  }

  .counsel-only-icon {
    .icomoon-matrix-setup('\e64e');
  }

  .redacted-icon {
    .icomoon-matrix-setup('\e64f');
  }

  .email-icon {
    .fontawesome-setup('\f003');
  }

  .no-email-icon {
    .icomoon-matrix-setup('\e651');
  }

  .discovery-icon {
    .icomoon-matrix-setup('\e652');
  }

  .assessment-icon {
    .icomoon-matrix-setup('\e650');
  }

  .expert-witness-icon {
    .fontawesome-setup('\f0f0');
  }

  .text-icon {
    .fontawesome-setup('\f031');
  }

  .copy-paste-icon {
    .fontawesome-setup('\f0c5');
  }

  .none-known-icon {
    .fontawesome-setup('\f069');
  }

  .evidence-icon {
    .icomoon-matrix-setup('\e655');
  }

  .checkbox-unchecked-icon {
    .icomoon-matrix-setup('\e60e');
  }

  .checkbox-checked-icon {
    .icomoon-matrix-setup('\e656');
  }

  .checkbox-checked-2-icon {
    .icomoon-matrix-setup('\e657');
  }

  .group-icon {
    .icomoon-matrix-setup('\e659');
  }

  .conversation-icon {
    .fontawesome-setup('\f086');
  }

  .expert-icon {
    .fontawesome-setup('\f0a3');
  }

  .print-icon {
    .fontawesome-setup('\f02f');
  }

  .phone-icon {
    .fontawesome-setup('\f095');
  }

  .impeachment-icon {
    .fontawesome-setup('\f0e3');
  }

  .matrix-icon {
    .icomoon-matrix-setup('\e65a');
  }

  .risk-icon {
    .icomoon-matrix-setup('\e65c');
  }

  .living-with-icon {
    .icomoon-matrix-setup('\e660');
  }

  .guardian-icon {
    .icomoon-matrix-setup('\e65f');
  }

  .app-initial-icon {
    .icomoon-matrix-setup('\e995');
  }

  .app-renewal-icon {
    .icomoon-matrix-setup('\e996');
  }

  .upload-file-icon {
    .icomoon-matrix-setup('\e998');
  }

  .upload-scan-icon {
    .icomoon-matrix-setup('\e997');
  }

  .issue-icon {
    .icomoon-matrix-setup('\e99a');
  }

  .license-icon {
    .icomoon-matrix-setup('\e9a4');
  }

  .remove-image-icon {
    .icomoon-matrix-setup('\e9a7');
  }

  .remove-all-icon {
    .icomoon-matrix-setup('\e9a8');
  }

  .deskew-icon {
    .icomoon-matrix-setup('\e9a9');
  }

  .mirror-icon {
    .icomoon-matrix-setup('\e9ab');
  }

  .flip-icon {
    .icomoon-matrix-setup('\e9aa');
  }

  .crime-scene-icon {
    .icomoon-matrix-setup('\e9ad');
  }

  .executing-search-icon {
    .icomoon-matrix-setup('\e9ae');
  }

  .inspection-icon {
    .icomoon-matrix-setup('\e9af');
  }

  .arrow-bent-icon {
    .icomoon-matrix-setup('\e9b0');
  }

  .dollar-circle-icon {
    .icomoon-matrix-setup('\e9b1');
  }

  .crown-icon {
    .icomoon-matrix-setup('\e9b8');
  }

  .open-claim-icon {
    .icomoon-matrix-setup('\e9b9');
  }

  .app-icon {
    .icomoon-matrix-setup('\e9ac');
  }

  .registered-user-icon {
    .icomoon-matrix-setup('\e99f');
  }

  .attorney-fees-icon {
    .icomoon-matrix-setup('\e999');
  }

  .icon-upload-scan:before {
    content: "\e997";
  }

  .icon-upload-file:before {
    content: "\e998";
  }

  .discovery-published-icon {
    .icomoon-matrix-setup('\e900');
  }

  .prefatory-language-icon {
    .icomoon-matrix-setup("\e649");
  }

  .court-no-file-icon {
    .icomoon-matrix-setup('\e901');
  }

  .court-file-icon {
    .icomoon-matrix-setup('\e902');
  }

  .referenced-icon {
    .icomoon-matrix-setup('\e903');
  }

  .portal-no-access-icon {
    .icomoon-matrix-setup('\e904');
  }

  .portal-icon {
    .fontawesome-setup('\f0ac');
  }

  .codefendants-icon {
    //.icomoon-matrix-setup('\e907'); // vertical version
    .icomoon-matrix-setup('\e908'); // horizontal version
  }

  .no-record-icon {
    .icomoon-matrix-setup('\e90a');
  }

  .certificate-icon {
    .icomoon-matrix-setup('\e909');
  }

  .card-view-icon {
    .icomoon-matrix-setup('\e90b');
  }

  .relationships-icon {
    .icomoon-matrix-setup('\e90c');
  }

  .errors-icon {
    .fontawesome-setup('\f06a');
  }

  .request-icon {
    .icomoon-matrix-setup('\e90d');
  }

  .make-demand-icon {
    .icomoon-matrix-setup('\e90d');
  }

  .workflow-icon {
    .icomoon-matrix-setup('\e90e');
  }

  .trigger-icon {
    .icomoon-matrix-setup('\e910');
  }

  .location-icon {
    .icomoon-matrix-setup('\e911');
  }

  .case-closed-icon {
    .icomoon-matrix-setup('\e912');
  }

  .file-explorer-icon {
    .icomoon-matrix-setup('\e913');
  }

  .report-icon {
    .icomoon-matrix-setup('\e926');
  }

  .data-explorer-icon {
    .icomoon-matrix-setup('\e915');
  }

  .auto-tasks-icon {
    .icomoon-matrix-setup('\e916');
  }

  .matter-types-icon {
    .icomoon-matrix-setup('\e917');
  }

  .reorder-icon {
    .icomoon-matrix-setup('\e923');
  }

  .long-arrow-right-icon {
    .fontawesome-setup('\f178')
  }

  .supersede-icon {
    .icomoon-matrix-setup('\e918');
  }

  .supplemental-icon {
    .fontawesome-setup('\f0c5');
  }

  .attorney-withheld-icon {
    .icomoon-matrix-setup('\e919');
  }

  .civil-icon {
    .icomoon-matrix-setup('\e91a');
  }

  .ready-to-publish-icon {
    .icomoon-matrix-setup('\e91b');
  }

  .draft-icon {
    .icomoon-matrix-setup('\e91c');
  }

  .secret-icon {
    .icomoon-matrix-setup('\e91d');
  }

  .secret-remove-icon {
    .icomoon-matrix-setup('\e91e');
  }

  .other-media-icon {
    .icomoon-matrix-setup('\e91f');
  }

  .container-icon {
    .icomoon-matrix-setup('\e920');
  }

  .container-broken-icon {
    .icomoon-matrix-setup('\e922');
  }

  .snippet-icon {
    .icomoon-matrix-setup('\e921');
  }

  .diamond-icon {
    .icomoon-matrix-setup('\e924');
  }

  .triangle-icon {
    .icomoon-matrix-setup('\e925');
  }

  .folder-icon {
    .fontawesome-setup('\f07b');
  }

  .arrow-circle-down-icon {
    .fontawesome-setup('\f0ab');
  }

  .receive-icon {
    .fontawesome-setup('\f01a');
  }

  .release-icon {
    .fontawesome-setup('\f01b');
  }

  .arrow-left-icon {
    .fontawesome-setup('\f060');
  }

  .assigned-icon {
    .icomoon-matrix-setup('\e933');
  }

  .biohazard-icon {
    .icomoon-matrix-setup('\e938');
  }

  .examined-icon {
    .icomoon-matrix-setup('\e939');
  }

  .personal-property-icon {
    .icomoon-matrix-setup('\e93a');
  }

  .start-icon {
    .fontawesome-setup('\f01d');
  }

  .in-progress-icon {
    .icomoon-matrix-setup('\e93b');
  }

  .relocate-icon {
    .icomoon-matrix-setup('\e93c');
  }

  .submission-history-icon {
    .icomoon-matrix-setup('\e93d');
  }

  .resubmission-icon {
    .icomoon-matrix-setup('\e93e');
  }

  .new-dropdown-icon {
    .icomoon-matrix-setup('\e93f');
  }

  .qui-tam-icon {
    .icomoon-matrix-setup('\e940');
  }

  .checklist-icon {
    .icomoon-matrix-setup('\e951');
  }

  .service-icon {
    .icomoon-matrix-setup('\e952');
  }

  .link-horizontal-icon {
    .icomoon-matrix-setup('\e953');
  }

  .add-checklist-icon {
    .icomoon-matrix-setup('\e954');
  }

  .add-task-icon {
    .icomoon-matrix-setup('\e956');
  }

  .to-do-icon {
    .icomoon-matrix-setup('\e957');
  }

  .question-square-icon {
    .icomoon-matrix-setup('\e958');
  }

  .times-square-icon {
    .icomoon-matrix-setup('\e959');
  }

  .appointment-square-icon {
    .icomoon-matrix-setup('\e95a');
  }

  .appointment-linked-square-icon {
    .icomoon-matrix-setup('\e95b');
  }

  .defendant-2-icon {
    .icomoon-matrix-setup('\e95c');
  }

  .suspect-2-icon {
    .icomoon-matrix-setup('\e961');
  }

  .victim-2-icon {
    .icomoon-matrix-setup('\e95f');
  }

  .provider-icon {
    .icomoon-matrix-setup('\e95d');
  }

  .claimant-icon {
    .icomoon-matrix-setup('\e960');
  }

  .lawyer-icon {
    .icomoon-matrix-setup('\e962');
  }

  .other-party-2-icon {
    .icomoon-matrix-setup('\e95e');
  }

  .push-pin-icon {
    .icomoon-matrix-setup('\e965');
  }

  .workflow-child-icon {
    .icomoon-matrix-setup('\e90f');
  }

  .workflow-child-2-icon {
    .icomoon-matrix-setup('\e966');
  }

  .email-message-icon {
    .icomoon-matrix-setup('\e977');
  }

  .text-message-icon {
    .icomoon-matrix-setup('\e978');
  }

  .mobile-message-icon {
    .icomoon-matrix-setup('\e979');
  }

  .change-icon {
    .icomoon-setup('\e2d2');
  }
  
  .circle-no-icon {
    .icomoon-matrix-setup('\e983');
  }

  .guardian-unproven-icon {
    .icomoon-matrix-setup('\e994');
  }

  .circle-na-icon {
    .icomoon-matrix-setup('\e984');
  }

  .edit2-icon {
    .icomoon-matrix-setup('\e993');
  }

  .full-text-search-icon {
    .icomoon-matrix-setup('\e992');
  }

  .gal-icon {
    .icomoon-matrix-setup('\e991');
  }

  .attempt-icon {
    .icomoon-matrix-setup('\e98f');
  }

  .change2-icon {
    .icomoon-matrix-setup('\e990');
  }

  .undo2-icon {
    .icomoon-matrix-setup('\e98a');
  }

  .unassign-icon {
    .icomoon-matrix-setup('\e98b');
  }

  .reassign-icon {
    .icomoon-matrix-setup('\e98c');
  }

  .swap-icon {
    .icomoon-matrix-setup('\e98d');
  }

  .close-icon {
    .icomoon-matrix-setup('\e98e');
  }

  .activity-icon {
    .icomoon-matrix-setup('\e987');
  }

  .add-activity-icon {
    .icomoon-matrix-setup('\e988');
  }

  .circle-new-icon {
    .icomoon-matrix-setup('\e985');
  }

  .circle-yes-icon {
    .icomoon-matrix-setup('\e986');
  }

  .circle-check-icon {
    .icomoon-matrix-setup('\e97d');
  }

  .circle-x-icon {
    .icomoon-matrix-setup('\e97e');
  }

  .circle-warning-icon {
    .icomoon-matrix-setup('\e97f');
  }

  .circle-check-icon {
    .icomoon-matrix-setup('\e97d');
  }

  .na-icon {
    .icomoon-matrix-setup('\e97b');
  }

  .file-referenced-icon {
    .icomoon-matrix-setup('\e9ba');
  }

  .file-network-icon {
    .icomoon-matrix-setup('\e9bb');
  }

  .circle-hold-icon {
    .icomoon-matrix-setup('\e9bc');
  }

  .syringe-icon {
    .icomoon-matrix-setup('\e9bd');
  }

  .add-multi-evidence-icon {
    .icomoon-matrix-setup('\e9be');
  }

.overview-icon {
    .icomoon-matrix-setup('\e9c7');
  }
.awaiting-clearance-icon {
    .icomoon-matrix-setup('\e9c8');
  }
.intake-incomplete-icon {
    .icomoon-matrix-setup('\e9c9');
  }
.unlock-icon {
    .icomoon-matrix-setup('\e9ca');
  }
.event-timeline-icon {
    .icomoon-matrix-setup('\e9cb');
  }

.system-report-icon {
    .icomoon-matrix-setup('\e9cc');
  }

.add-sub-item-icon {
    .icomoon-matrix-setup('\e9cd');
  }

.shared-icon {
    .icomoon-matrix-setup('\e9ce');
  }

.no-print-icon {
    .icomoon-matrix-setup('\e9cf');
  }

.investigation-icon {
    .icomoon-matrix-setup('\e9d3');
  }

.dismissed-call-1-color-icon {
    .icomoon-matrix-setup('\e9d2');
  }

.call-dismissed-icon {
    .icomoon-matrix-setup('\e9d4');
  }

.victim-rights-icon {
    .icomoon-matrix-setup('\e9d7');
  }

.car-crash-icon {
    .icomoon-matrix-setup('\e9d8');
  }

.field-interview-icon {
  .icomoon-setup('\e06f');
}
.add-text-icon {
    .icomoon-matrix-setup('\e9d9');
  }

.incident-review-icon {
    .icomoon-matrix-setup('\e9e1');
  }
.key-matter-icon {
    .icomoon-matrix-setup('\ea0d');
  }
.merge-2-icon {
    .icomoon-matrix-setup('\ea0e');
  }
.concurrent-icon {
    .icomoon-matrix-setup('\ea0f');
  }
.joint-several-icon {
    .icomoon-matrix-setup('\ea10');
  }
.kiosk-icon {
    .icomoon-matrix-setup('\ea11');
  }
.photo-book-icon {
    .icomoon-matrix-setup('\ea13');
  }
.ongoing-icon {
    .icomoon-matrix-setup('\ea12');
  }
.hide-icon {
    .icomoon-matrix-setup('\ea15');
  }
.show-icon {
    .icomoon-matrix-setup('\ea14');
  }
.triangle-outline-icon {
    .icomoon-matrix-setup('\ea16');
  }
.container-add-icon {
    .icomoon-matrix-setup('\ea17');
  }

.filing-rejected-icon {
    .icomoon-matrix-setup('\ea1c');
  }

.new-icon {
    .icomoon-matrix-setup('\ea1d');
  }

.timeline-vertical-icon {
    .icomoon-matrix-setup('\ea1e');
  }

.teams-icon {
    .icomoon-matrix-setup('\ea0c');
  }
.matter-info-only-icon {
    .icomoon-matrix-setup('\ea0b');
  }
.location-picker-icon {
    .icomoon-matrix-setup('\ea06');
  }
.evidence-analysis-icon {
    .icomoon-matrix-setup('\ea07');
  }
.evidence-intake-icon {
    .icomoon-matrix-setup('\ea08');
  }
.evidence-request-icon {
    .icomoon-matrix-setup('\ea09');
  }
.evidence-seizure-icon {
    .icomoon-matrix-setup('\ea0a');
  }

.speed-limit-85-icon {
    .icomoon-matrix-setup('\ea04');
  }

.speed-limit-80-icon {
    .icomoon-matrix-setup('\e9f8');
  }

.speed-limit-75-icon {
    .icomoon-matrix-setup('\ea05');
  }

.speed-limit-70-icon {
    .icomoon-matrix-setup('\ea03');
  }

.speed-limit-65-icon {
    .icomoon-matrix-setup('\ea02');
  }

.speed-limit-60-icon {
    .icomoon-matrix-setup('\ea01');
  }

.speed-limit-55-icon {
    .icomoon-matrix-setup('\ea00');
  }

.speed-limit-50-icon {
    .icomoon-matrix-setup('\e9ff');
  }

.speed-limit-45-icon {
    .icomoon-matrix-setup('\e9fe');
  }

.speed-limit-40-icon {
    .icomoon-matrix-setup('\e9fd');
  }

.speed-limit-35-icon {
    .icomoon-matrix-setup('\e9fc');
  }

.speed-limit-30-icon {
    .icomoon-matrix-setup('\e9fb');
  }

.speed-limit-25-icon {
    .icomoon-matrix-setup('\e9fa');
  }

.speed-limit-20-icon {
    .icomoon-matrix-setup('\e9f9');
  }

.speed-limit-15-icon {
    .icomoon-matrix-setup('\e9e0');
  }

.railroad-crossing-icon {
    .icomoon-matrix-setup('\e9e2');
  }

.pedestrian-crosswalk-icon {
    .icomoon-matrix-setup('\e9e3');
  }

.stop-icon {
    .icomoon-matrix-setup('\e9e4');
    color: #ae2025;
  }

.yield-icon {
    .icomoon-matrix-setup('\e9e5');
    color: #ae2025;
  }

.wrong-way-icon {
    .icomoon-matrix-setup('\e9e6');
    color: #ae2025;
  }

.school-zone-icon {
    .icomoon-matrix-setup('\e9e7');
      color: #e4762b;
  }

.left-straight-icon {
    .icomoon-matrix-setup('\e9e8');
  }

.straight-only-icon {
    .icomoon-matrix-setup('\e9e9');
  }

.right-only-icon {
    .icomoon-matrix-setup('\e9ea');
  }

.left-only-icon {
    .icomoon-matrix-setup('\e9eb');
  }

.median-icon {
    .icomoon-matrix-setup('\e9ec');
  }

.do-not-enter-icon {
    .icomoon-matrix-setup('\e9ed');
      color: #b62e29;
  }

.left-only-left-straight-icon {
    .icomoon-matrix-setup('\e9ee');
  }

.no-right-turn-icon {
    .icomoon-matrix-setup('\e9ef');
  }

.no-left-turn-icon {
    .icomoon-matrix-setup('\e9f0');
  }

.no-u-turn-icon {
    .icomoon-matrix-setup('\e9f1');
  }

.turnabout-icon {
    .icomoon-matrix-setup('\e9f2');
      color: #e8bb00;
  }

.u-turn-icon {
    .icomoon-matrix-setup('\e9f3');
      color: #e8bb00;
  }

.dead-end-icon {
    .icomoon-matrix-setup('\e9f4');
      color: #e8bb00;
  }

.slow-icon {
    .icomoon-matrix-setup('\e9f5');
      color: #e8bb00;
  }

.merge-icon {
    .icomoon-matrix-setup('\e9f6');
    color: #e8bb00;
  }

.merge-left-icon {
    .icomoon-matrix-setup('\e9f7');
      color: #f37937;
  }

.multiple-select-icon {
    .icomoon-matrix-setup('\e9da');
  }
.compass-icon {
    .icomoon-matrix-setup('\e9db');
  }
.draw-straight-icon {
    .icomoon-matrix-setup('\e9dc');
  }
.draw-free-icon {
    .icomoon-matrix-setup('\e9dd');
  }

.pdf-secure-icon {
    .path1 {
      .icomoon-matrix-setup('\e97c');
      color: rgb(0, 0, 0);
    }

    .path2 {
      .icomoon-matrix-setup('\e980');
      color: @pdf;
    }
  }

.zip-automatic-icon {
    .path1 {
      .icomoon-matrix-setup('\e9d5');
      color: rgb(203, 185, 104);
    }

    .path2 {
      .icomoon-matrix-setup('\e9d6');
      color: rgb(5, 6, 5);
    }
  }

.incident-review-color-icon {
    .path1 {
      .icomoon-matrix-setup('\e9de');
        color: rgb(94,95,97);
    }

    .path2 {
      .icomoon-matrix-setup('\e9df');
      color: @attention;
    }
  }

.shared-outlined-icon {
    .path1 {
      .icomoon-matrix-setup('\ea18');
        color: rgb(255, 255, 255);
    }

    .path2 {
      .icomoon-matrix-setup('\ea19');
    }
  }


.lock-outlined-icon {
    .path1 {
      .icomoon-matrix-setup('\ea1a');
        color: rgb(255, 255, 255);
    }

    .path2 {
      .icomoon-matrix-setup('\ea1b');
    }
  }

.cal-270-icon {
    .path1 {
      .icomoon-matrix-setup('\ea1f');
         color: rgb(16, 48, 68);
    }

    .path2 {
      .icomoon-matrix-setup('\ea20');
        margin-left: -1em;
        color: rgb(255, 255, 255);
    }
  }

.hourglass-empty-icon {
    .path1 {
      .icomoon-matrix-setup('\ea21');
       color: rgb(196, 87, 43);
    }

    .path2 {
      .icomoon-matrix-setup('\ea22');
        margin-left: -1em;
        color: rgb(255, 255, 255);
    }
  }

.hourglass-full-icon {
    .path1 {
      .icomoon-matrix-setup('\ea23');
       color: rgb(16, 48, 68);
    }

    .path2 {
      .icomoon-matrix-setup('\ea24');
        margin-left: -1em;
        color: rgb(255, 255, 255);
    }
  }

.hourglass-halfway-icon {
    .path1 {
      .icomoon-matrix-setup('\ea25');
         color: rgb(86, 140, 63);
    }

    .path2 {
      .icomoon-matrix-setup('\ea26');
        margin-left: -1em;
        color: rgb(255, 255, 255);
    }
  }

  .pdf-secure2-icon {
    .icomoon-matrix-setup('\e989');
    color: @pdf;
  }
  
  .pdf-searchable-icon {
    .icomoon-matrix-setup('\e982');
    color: @pdf;
  }
   
  .rule-icon {
    .icomoon-matrix-setup('\e97a');
  }

  .record-decision-icon {
    .icomoon-matrix-setup('\e981');
  }

  // Property Icons
  .cd-property-icon {
    .icomoon-matrix-setup('\e65d');
  }
    
  .drug-property-icon {
    .drugs-icon;
  }
    
  .financial-property-icon {
    .fontawesome-setup('\f155');
  }
    
  .other-property-icon {
    .icomoon-matrix-setup('\e65e');
  }
    
  .real-estate-property-icon {
    .fontawesome-setup('\f015');
  }
    
  .trace-evidence-property-icon {
    .evidence-icon;
  }
    
  .vehicle-property-icon {
    .fontawesome-setup('\f1b9');
  }
    
  .exclamation-circle-icon {
    .fontawesome-setup('\f06a');
  }
    
  .weapon-property-icon {
    .crimes-icon;
  }
    
  .hosted-evidence-icon {
    .icomoon-setup('\e013');
  }
    
  .back-icon {
    .icomoon-matrix-setup('\e800');
  }
    
  .tag-icon {
    .fontawesome-setup('\f02b');
  }
    
  .expander-icon {
    .fontawesome-setup('\f078');
    cursor: pointer;
  
    &:before, &:after {
      font-size: 1.1rem;
    }
  
    &.active {
      &:before, &:after {
        content: '\f077';
      }
    }
  }
    
  .speedy-trial-icon {
    .icomoon-setup('\e04e');
  }
    
  .journal-icon {
    .fontawesome-setup('\f0cb');
  }
    
  .costs-icon {
    .fontawesome-setup('\f155');
  }
    
  .statistics-icon {
    .fontawesome-setup('\f080');
  }

  .red-text-icon {
    .path1 {
      .icomoon-matrix-setup('\e927');
      color: #f00;
    }

    .path2 {
      .icomoon-matrix-setup('\e928');
    }
  }

  .color-text-icon {
    .path1 {
      .icomoon-matrix-setup('\e92c');
      color: #949494;
    }

    .path2 {
      .icomoon-matrix-setup('\e929');
    }

    .path3 {
      .icomoon-matrix-setup('\e92a');
    }
  }

  .yellow-highlight-icon {
    .path1 {
      .icomoon-matrix-setup('\e92b');
    }

    .path2 {
      .icomoon-matrix-setup('\e92e');
    }

    .path3 {
      .icomoon-matrix-setup('\e92f');
      color: #ff0;
    }
  }

  .highlight-icon {
    .path1 {
      .icomoon-matrix-setup('\e92d');
    }

    .path2 {
      .icomoon-matrix-setup('\e930');
    }

    .path3 {
      .icomoon-matrix-setup('\e931');
      color: #949494;
    }

    .path4 {
      .icomoon-matrix-setup('\e932');
    }
  }

  .assigned-check-icon {
    .path1 {
      .icomoon-matrix-setup('\e934');
    }

    .path2 {
      .icomoon-matrix-setup('\e935');
    }

    .path3 {
      .icomoon-matrix-setup('\e936');
    }

    .path4 {
      .icomoon-matrix-setup('\e937');
      color: @attention;
    }
  }

  .subrogation-icon {
    .path1 {
      .icomoon-matrix-setup('\e65b');
    }

    .path2 {
      .icomoon-matrix-setup('\e661');
      color: @goal-success-color;
    }
  }

  .expunged-partially-icon {
    .path1 {
      .icomoon-matrix-setup('\e941');
    }

    .path2 {
      .icomoon-matrix-setup('\e942');
      color: @attention;
    }
  }

  .sealed-partially-icon {
    .path1 {
      .icomoon-matrix-setup('\e943');
    }

    .path2 {
      .icomoon-matrix-setup('\e944');
      color: @attention;
    }
  }

  .sealed-icon {
    .path1 {
      .icomoon-matrix-setup('\e945');
    }

    .path2 {
      .icomoon-matrix-setup('\e946');
      color: @attention;
    }
  }

  .sealed-pending-icon {
    .path1 {
      .icomoon-matrix-setup('\e947');
    }

    .path2 {
      .icomoon-matrix-setup('\e948');
      color: @goal-success-color;
    }
  }

  .sealed-denied-icon {
    .path1 {
      .icomoon-matrix-setup('\e949');
    }

    .path2 {
      .icomoon-matrix-setup('\e94a');
      color: @attention;
    }
  }

  .expunged-denied-icon {
    .path1 {
      .icomoon-matrix-setup('\e94b');
    }

    .path2 {
      .icomoon-matrix-setup('\e94c');
      color: @attention;
    }
  }

  .expunged-pending-icon {
    .path1 {
      .icomoon-matrix-setup('\e94d');
    }

    .path2 {
      .icomoon-matrix-setup('\e94e');
      color: @goal-success-color;
    }
  }

  .expunged-icon {
    .path1 {
      .icomoon-matrix-setup('\e94f');
    }

    .path2 {
      .icomoon-matrix-setup('\e950');
      color: @attention;
    }
  }

  .file-confidential-icon {
    .path1 {
      .icomoon-matrix-setup('\e963');
    }

    .path2 {
      .icomoon-matrix-setup('\e964');
      color: @attention;
    }
  }

  .email-sent-icon {
    .path1 {
      .icomoon-matrix-setup('\e967');
    }

    .path2 {
      .icomoon-matrix-setup('\e968');
      color: @heading-color;
    }
  }

  .email-no-icon {
    .path1 {
      .icomoon-matrix-setup('\e972');
    }

    .path2 {
      .icomoon-matrix-setup('\e973');
      color: @priority-red;
    }
  }
  
  .email-no-overridden-icon {
    .path1 {
      .icomoon-matrix-setup('\e972');
    }

    .path2 {
      .icomoon-matrix-setup('\e973');
      color: @text-color-very-light;
    }
  }

  .email-yes-icon {
    .path1 {
      .icomoon-matrix-setup('\e96f');
    }

    .path2 {
      .icomoon-matrix-setup('\e970');
      color: @victim;
    }
  }
  
  .email-yes-overridden-icon {
    .path1 {
      .icomoon-matrix-setup('\e96f');
    }

    .path2 {
      .icomoon-matrix-setup('\e970');
      color: @text-color-very-light;
    }
  }
  
  .text-sent-icon {
    .path1 {
      .icomoon-matrix-setup('\e96d');
    }

    .path2 {
      .icomoon-matrix-setup('\e96e');
      color: @heading-color;
    }
  }

  .text-no-icon {
    .path1 {
      .icomoon-matrix-setup('\e96b');
    }

    .path2 {
      .icomoon-matrix-setup('\e96c');
      color: @priority-red;
    }
  }

  .text-no-overridden-icon {
    .path1 {
      .icomoon-matrix-setup('\e96b');
    }

    .path2 {
      .icomoon-matrix-setup('\e96c');
      color: @text-color-very-light;
    }
  }

  .text-yes-icon {
    .path1 {
      .icomoon-matrix-setup('\e969');
    }

    .path2 {
      .icomoon-matrix-setup('\e96a');
      color: @victim;
    }
  }

  .text-yes-overridden-icon {
    .path1 {
      .icomoon-matrix-setup('\e969');
    }

    .path2 {
      .icomoon-matrix-setup('\e96a');
      color: @text-color-very-light;
    }
  }

  .email-unavailable-icon {
    .path1 {
      .icomoon-matrix-setup('\e971');
    }

    .path2 {
      .icomoon-matrix-setup('\e974');
      color: @attention;
    }
  }

  .text-unavailable-icon {
    .path1 {
      .icomoon-matrix-setup('\e975');
    }

    .path2 {
      .icomoon-matrix-setup('\e976');
      color: @attention;
    }
  }

  .registered-user-x-icon {
    .path1 {
      .icomoon-matrix-setup('\e99b');
    }

    .path2 {
      .icomoon-matrix-setup('\e99c');
      color: @heading-color;
    }
  }

  .registered-user-exclamation-icon {
    .path1 {
      .icomoon-matrix-setup('\e99d');
    }

    .path2 {
      .icomoon-matrix-setup('\e99e');
      color: @attention;
    }
  }
   
  .license-mismatch1-icon {
    .path1 {
      .icomoon-matrix-setup('\e9a0');
    }

    .path2 {
      .icomoon-matrix-setup('\e9a1');
      color: @attention;
    }
  }

  .license-mismatch2-icon {
    .path1 {
      .icomoon-matrix-setup('\e9a2');
    }

    .path2 {
      .icomoon-matrix-setup('\e9a3');
      color: @heading-color;
    }
  }

  .license-pending-icon {
    .path1 {
      .icomoon-matrix-setup('\e9a5');
    }

    .path2 {
      .icomoon-matrix-setup('\e9a6');
      color: @attention;
    }
  }

  .license-questionable-icon {
    .path1 {
      .icomoon-matrix-setup('\e9b6');
    }

    .path2 {
      .icomoon-matrix-setup('\e9b7');
      margin-left: -1em;
      color: rgb(196, 89, 0);
    }
  }

  .dollar-arrow-icon {
    .path1 {
      .icomoon-matrix-setup('\e9b2');
      color: rgb(87, 140, 17);
    }

    .path2 {
      .icomoon-matrix-setup('\e9b3');
    }
  }

  .app-update-icon {
    .path1 {
      .icomoon-matrix-setup('\e9b4');
    }
    .path2 {
      .icomoon-matrix-setup('\e9b5');
      margin-left: -1em;
      color: rgb(251, 176, 59);
    }
  }


  .location-unknown-icon {
    .path1 {
      .icomoon-matrix-setup('\e9c3');
    }

    .path2 {
      .icomoon-matrix-setup('\e9c4');
      margin-left: -1em;
      color: rgb(234, 189, 0);
    }
  }

    .location-do-not-verify-icon {
    .path1 {
      .icomoon-matrix-setup('\e9c1');
    }

    .path2 {
      .icomoon-matrix-setup('\e9c2');
      margin-left: -1em;
      color: rgb(237, 28, 36);
    }
  }

      .location-verified-icon {
    .path1 {
      .icomoon-matrix-setup('\e9bf');
    }

    .path2 {
      .icomoon-matrix-setup('\e9c0');
      margin-left: -1em;
      color: rgb(87, 140, 17);
    }
  }

.location-failed-icon {
    .path1 {
      .icomoon-matrix-setup('\e9c5');
    }

    .path2 {
      .icomoon-matrix-setup('\e9c6');
      margin-left: -1em;
      color: rgb(237, 28, 36);
    }
  }

.dismissed-call-icon {
    .path1 {
      .icomoon-matrix-setup('\e9d0');
    }

    .path2 {
      .icomoon-matrix-setup('\e9d1');
      margin-left: -1em;
      color: rgb(196, 89, 0);
    }
  }



