@import 'spry-variables.less';

.box-model-fix {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

.transition {
  transition: color 0.2s ease, background-color 0.2s ease;
  -moz-transition: color 0.2s ease, background-color 0.2s ease;
  -o-transition: color 0.2s ease, background-color 0.2s ease;
  -webkit-transition: color 0.2s ease, background-color 0.2s ease;
}

// Clearfix
//
// For modern browsers
// 1. The space content is one way to avoid an Opera bug when the
//    contenteditable attribute is included anywhere else in the document.
//    Otherwise it causes space to appear at the top and bottom of elements
//    that are clearfixed.
// 2. The use of `table` rather than `block` is only necessary if using
//    `:before` to contain the top-margins of child elements.
//
// Source: http://nicolasgallagher.com/micro-clearfix-hack/

.clearfix() {
  &:before,
  &:after {
    content: " "; // 1
    display: table; // 2
  }
  &:after {
    clear: both;
  }
}

.dropdown-shadow {
  -webkit-box-shadow: 0px 10px 14px 1px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 10px 14px 1px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 10px 14px 1px rgba(0, 0, 0, 0.3);
}
.minimal-shadow {
  -webkit-box-shadow: 0px 5px 7px 1px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 5px 7px 1px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 5px 7px 1px rgba(0, 0, 0, 0.2);
}
.section-body-shadow {
  -webkit-box-shadow: @section-body-shadow-offset-x @section-body-shadow-offset-y @section-body-shadow-radius rgba(0, 0, 0, 0.4);
  -moz-box-shadow: @section-body-shadow-offset-x @section-body-shadow-offset-y @section-body-shadow-radius rgba(0, 0, 0, 0.4);
  box-shadow: @section-body-shadow-offset-x @section-body-shadow-offset-y @section-body-shadow-radius rgba(0, 0, 0, 0.4);
}

.section-header-boxed-shadow {
  -webkit-box-shadow: 0 2px 2px rgba(0,0,0,.25);
  -moz-box-shadow: 0 2px 2px rgba(0,0,0,.25);
  box-shadow: 0 2px 2px rgba(0,0,0,.25);
}

.section-header-boxed-shadow-subdued {
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0 1px 2px rgba(0,0,0,.25);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
}

.active() {
  background-color: @complimentary-background-color;
  opacity: 1;
  color: @foreground-reverse;

  a {
    color: @link-color-reverse;
  }

  a[class*="button-"][disabled] {
    color: fade(@foreground-reverse, 30%);

    &:before {
      color: fade(@foreground-reverse, 30%);
    }
  }

  span, p, h1, h2, h3, h4, h5, h6 {
    color: @foreground-reverse;
  }

  .dark-color {
    color: @background-white;
  }

  &:before {
    color: fade(@foreground-reverse, 100%);
  }

  .section-header-status {
    color: @background-white;
  }

  .body-text-style {
    color: @foreground-reverse;
  }
}

@dialog-top-padding: @base-padding;
@dialog-left-padding: @base-padding * 2;
@dialog-right-padding: @base-padding * 2;
@dialog-bottom-padding: @base-padding;

.dialog-box() {
  .dropdown-shadow();
  background-color: @header-gray;
  border: 0 none;
  border-bottom: 3px solid @attention;

  position: absolute;
  display: none;
  z-index: 1000;

  padding-top: @dialog-top-padding;
  padding-bottom: @dialog-bottom-padding;
  padding-left: @dialog-left-padding;
  padding-right: @dialog-right-padding;
}

.background-header-gradation {
  background: linear-gradient(to left, @complimentary-background-color 0%,@heading-color 100%);
}

.fontSizeConservingLineHeight(@fontSizeEms) {
  font-size: @fontSizeEms;
  line-height: 1em / @fontSizeEms;
}

.positioning(@overflow-x: hidden, @overflow-y: auto, @top: 0) {
  overflow-x: @overflow-x;
  overflow-y: @overflow-y;
  top: @top;
  bottom: 0;
  position: fixed;
  height: auto;
  min-height: 0;
  /*position: static; // 5 properties for non-fixed positioning
    height: 100%;
    min-height: 0;
    overflow-x: visible;
    overflow-y: visible;*/
}

.full-image(@url){
  display: flex;
  div {
    background: @url no-repeat top center;
    background-size: contain;
    width: 100%;
    min-height: 16em;
  }

  &.x2 div {
    min-height: 27em;
  }
}

.small-image(@url){
  display: flex;
  max-height: 9em;

  div {
    background: white @url no-repeat top center;
    background-size: cover;
    width: 100%;
    min-height: 9em;
  }
}
