html,
body,
#root,
.product-background {
  background-size: cover;
  background-attachment: fixed;
}

body,
#root {
  height: 100%;
  margin: 0;
  padding: 0;
  background-size: cover;
  display: flex;
  flex: 1 0 auto;
}

.field .field-validation-error,
.field .label,
.field > label {
  font-weight: 500 !important;
  color: #123044 !important;
}

#product-background {
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 'cover';
}

html:not(.browser-compat),
body#login-page,
#product-background {
  background: #fff url('../../shared/spry/images/login-bkgd-private-atty.jpg') no-repeat center center;
  background-size: cover;
  background-attachment: fixed;
}

.product-title-subheading {
  max-width: 70rem;
  font-size: 2rem;
  line-height: 2rem;
  color: white;
  text-align: center;
  font-style: italic;
  font-weight: 300;
  margin: auto;
}

.fileshare-and-discovery-subheading {
  max-width: 70rem;
  font-size: 2.5rem;
  line-height: 2rem;
  color: white;
  text-align: center;
  font-weight: 300;
  padding-top: 1rem;
  margin: auto;
}

.chrome-image {
  height: 56px;
  width: 60px;
  background: url('../../app/images/chrome-logo.jpg') no-repeat;
  background-size: contain;
}

#login-form {
  width: 34rem !important;
}

.notice-message.flex-bottom {
  flex-basis: 4rem;
}

#footer,
#sign-in {
  a {
    font-weight: 700;
    color: #a3c8ea;

    &:hover {
      color: lighten(#a3c8ea, 20%);
    }
  }
}

.action-buttons .pure-button,
.action-buttons .pure-button.pure-button-primary,
.pure-button.pure-button-primary,
button.primary-button {
  font-size: 24px; //Needed for accessibility color contrast

  padding: 1rem 3rem 1rem 3rem;
  &.signin-icon {
    padding: 1rem 1rem;
    &:before {
      font-size: 1.8rem;
    }
  }
}
